<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 20C20 17.3478 21.0536 14.8043 22.9289 12.9289C24.8043 11.0536 27.3478 10 30 10H70C72.6522 10 75.1957 11.0536 77.0711 12.9289C78.9464 14.8043 80 17.3478 80 20V80C81.3261 80 82.5979 80.5268 83.5355 81.4645C84.4732 82.4021 85 83.6739 85 85C85 86.3261 84.4732 87.5979 83.5355 88.5355C82.5979 89.4732 81.3261 90 80 90H65C63.6739 90 62.4021 89.4732 61.4645 88.5355C60.5268 87.5979 60 86.3261 60 85V75C60 73.6739 59.4732 72.4021 58.5355 71.4645C57.5979 70.5268 56.3261 70 55 70H45C43.6739 70 42.4021 70.5268 41.4645 71.4645C40.5268 72.4021 40 73.6739 40 75V85C40 86.3261 39.4732 87.5979 38.5355 88.5355C37.5979 89.4732 36.3261 90 35 90H20C18.6739 90 17.4021 89.4732 16.4645 88.5355C15.5268 87.5979 15 86.3261 15 85C15 83.6739 15.5268 82.4021 16.4645 81.4645C17.4021 80.5268 18.6739 80 20 80V20ZM35 25H45V35H35V25ZM45 45H35V55H45V45ZM55 25H65V35H55V25ZM65 45H55V55H65V45Z"
      fill="currentColor"
    />
  </svg>
</template>
