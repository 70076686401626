<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 20C17.3478 20 14.8043 21.0536 12.9289 22.9289C11.0536 24.8043 10 27.3478 10 30V35H90V30C90 27.3478 88.9464 24.8043 87.0711 22.9289C85.1957 21.0536 82.6522 20 80 20H20Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90 45H10V70C10 72.6522 11.0536 75.1957 12.9289 77.0711C14.8043 78.9464 17.3478 80 20 80H80C82.6522 80 85.1957 78.9464 87.0711 77.0711C88.9464 75.1957 90 72.6522 90 70V45ZM20 65C20 63.6739 20.5268 62.4021 21.4645 61.4645C22.4021 60.5268 23.6739 60 25 60H30C31.3261 60 32.5979 60.5268 33.5355 61.4645C34.4732 62.4021 35 63.6739 35 65C35 66.3261 34.4732 67.5979 33.5355 68.5355C32.5979 69.4732 31.3261 70 30 70H25C23.6739 70 22.4021 69.4732 21.4645 68.5355C20.5268 67.5979 20 66.3261 20 65ZM45 60C43.6739 60 42.4021 60.5268 41.4645 61.4645C40.5268 62.4021 40 63.6739 40 65C40 66.3261 40.5268 67.5979 41.4645 68.5355C42.4021 69.4732 43.6739 70 45 70H50C51.3261 70 52.5979 69.4732 53.5355 68.5355C54.4732 67.5979 55 66.3261 55 65C55 63.6739 54.4732 62.4021 53.5355 61.4645C52.5979 60.5268 51.3261 60 50 60H45Z"
      fill="currentColor"
    />
  </svg>
</template>
