<template>
  <div
    class="border border-blue-600 text-blue-600 rounded-full w-5 h-5 p-[2px]"
  >
    <component :is="computedComponent" class="w-full h-full" />
  </div>
</template>

<script>
  import EnterpriseVerifiedIcon from './icons/EnterpriseVerifiedIcon';
  import CraftCommerceVerifiedIcon from './icons/CraftCommerceVerifiedIcon';
  import CraftVerifiedIcon from './icons/CraftVerifiedIcon';

  export default {
    components: {
      CraftVerifiedIcon,
      CraftCommerceVerifiedIcon,
      EnterpriseVerifiedIcon,
    },
    props: {
      kind: {
        type: String,
        required: true,
      },
    },

    computed: {
      computedComponent() {
        switch (this.kind) {
          case 'craft':
            return 'craft-verified-icon';
          case 'commerce':
            return 'craft-commerce-verified-icon';
          case 'enterprise':
            return 'enterprise-verified-icon';
          default:
            return 'craft-verified-icon';
        }
      },
    },
  };
</script>
